<template lang="pug">
div
  .container-fluid
    .row
      .col
        h2.text-center {{plateName}}  

    .row
      .d-xs-none.col-sm-2
      .col-12.col-sm-8
        .form-group
          label(
            for="house"
            ) Назва вулиці
          StreetName(
            ref="search"
            )
      .d-xs-none.col-sm-2
  
  .container-fluid.full
    .row
      .col.preview
        // SVG defs
        svg(
          width="0",
          height="0",
          viewBox="0,0,0,0",
          viewport xmlns="http://www.w3.org/2000/svg"
        )
          SvgDefs(            
              :plateWidth = "this.plateWidth"            
              :clipWidth = '80'
              )
        // SVG Placeholder
        #containerPlaceholder(
          v-if="isPlaceholder",
          )
          svg#svgPlaceholder(
            width="100%", 
            :height="sizeM(240)",
            :viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(sizeXR(0)) +' '+ sizeM(360)", 
            :class="{ material: isMaterial}",             
            @click="isMaterial = !isMaterial", 
            viewport xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin meet")
                        
            rect.refPlate.plate(
              :width="sizeM(sizeXR(0))",
              :height="sizeM(320)" 
              rx="16" 
              ry="16" 
              fill="#fff" 
              stroke="#000" 
              stroke-width=".25"
              )
            g.refElements(style="display:none;")
              rect(
                ref="refLimit",
                :width="sizeM(sizeXR(0)-this.plateLimit)",
                :height="sizeM(320)",
                :x="sizeM(40)" 
                y="0" 
                fill="none" 
                stroke="none")
              rect(
                ref="refOffset",
                :width="sizeM(this.plateOffset)",
                :height="sizeM(320)",
                x="0" 
                y="0" 
                fill="none" 
                stroke="none"
                )

            text.prefix(
              :x="sizeM(50)",
              :y="sizeM(65)",
              :font-size="sizeM(44)" 
              letter-spacing="0.039em" 
              text-anchor="start" 
              style="font-weight: 400; font-family: DniproCity-SemiBold; fill: #000;"
              ) {{mainFamilyPlace}}
            
            text.name(
              ref="refName",
              :x="sizeM(47)",
              :y="sizeM(175)",
              :font-size="sizeM(112)"
              letter-spacing="0.03em" 
              text-anchor="start" 
              style="font-weight: 400; font-family: DniproCity-Medium; fill: #000;"
              ) {{baseNamePlace}}
            
            text.translit(
              ref="refTranslit",
              :x="sizeM(50)",
              :y="sizeM(285)",
              :font-size="sizeM(44)"
              letter-spacing="0.039em" 
              text-anchor="start" 
              style="font-weight: 400; font-family: DniproCity-Medium; fill: #000;"
              ) {{mainTranslitPlace}}             
            
            g.wave(
              :transform="'translate('+ sizeM(40) +','+ sizeM(214) +') scale('+ sizeM(1) +')'"
              )
              SvgWave(
                :plateWidth = "this.plateWidth" 
                :clipWidth = "80"
                )

            g.svgMaterial(
              style="display: none;",
              :transform="'scale('+ sizeM(1) +')'"
              )
              polygon.shade(
                points="0 0, 100 160, 0 320"
                fill="none"
                )
              g(:transform="'translate('+ sizeXR(320) +',0)'")
                polygon.shade(
                  points="320 0, 220 160, 320 320" 
                  fill="none")
              rect.reflex(
                :width="sizeXR(0)",
                height="320" 
                rx="16" 
                ry="16" 
                fill="none"
                )

            g.fix-marks(v-if ="store.fixersDisplay") 
              circle(
                cx="16" 
                cy="16" 
                r="3" 
                fill="#aaa"
                )
              circle(
                :cx="sizeM(sizeXR(0))-16",
                cy="16" 
                r="3" 
                fill="#aaa"
                )
              circle(
                :cx="sizeM(sizeXR(0))-16",
                :cy="sizeM(320)-16",
                r="3" 
                fill="#aaa"
                )
              circle(
                cx="16",
                :cy="sizeM(320)-16",
                r="3" 
                fill="#aaa"
                )

        // SVG Template
        #containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCart",
            :style="'top:'+ this.rectY +'px; right: calc(100vw - '+ this.rectR +'px); width:'+ this.rectW +'px; height:'+ this.rectH +'px;'",
            v-html="animationBuff"
            ) 

          svg#svgTemplate(
            width="100%", 
            :height="sizeM(240)",
            :viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(sizeXR(0)) +' '+ sizeM(360)", 
            :class="{ material: isMaterial}",            
            @click="isMaterial = !isMaterial", 
            viewport xmlns="http://www.w3.org/2000/svg" 
            preserveAspectRatio="xMidYMin meet")
            
            rect.refPlate.plate(
              :width="sizeM(sizeXR(0))",
              :height="sizeM(320)",
              ref="refReady",
              rx="16",
              ry="16",
              fill="#fff",
              stroke="#000", 
              stroke-width=".25",              
              )
            g.refElements(style="display:none;")
              rect(
                ref="refLimit",
                :width="sizeM(sizeXR(0)-this.plateLimit)",
                :height="sizeM(320)",
                :x="sizeM(40)" 
                y="0" 
                fill="none" 
                stroke="none"
                )
              rect(
                ref="refOffset",
                :width="sizeM(this.plateOffset)",
                :height="sizeM(320)",
                x="0" 
                y="0" 
                fill="none" 
                stroke="none"
                )

            text.prefix(
              :x="sizeM(50)",
              :y="sizeM(65)",
              :font-size="sizeM(44)" 
              letter-spacing="0.039em" 
              text-anchor="start" 
              style="font-weight: 400; font-family: DniproCity-SemiBold; fill: #000;"
              ) {{mainFamily}}
            
            text.name(
              ref="refName", 
              :x="sizeM(46)",
              :y="sizeM(175)",
              :font-size="sizeM(112)" 
              letter-spacing="0.03em" 
              text-anchor="start" 
              style="font-weight: 400; font-family: DniproCity-Medium; fill: #000;"
              ) {{baseName}}
            
            text.translit(
              ref="refTranslit",
              :x="sizeM(50)",
              :y="sizeM(285)",
              :font-size="sizeM(44)" 
              letter-spacing="0.039em" 
              text-anchor="start" 
              style="font-weight: 400; font-family: DniproCity-Medium; fill: #000;"
              ) {{mainTranslit}}
            
            g.wave(
              :transform="'translate('+ sizeM(40) +','+ sizeM(214) +') scale('+ sizeM(1) +')'"
              )
              SvgWave(
                :plateWidth = "this.plateWidth" 
                :clipWidth = "80"
                )

            g.svgMaterial(
              style="display: none;",
              :transform="'scale('+ sizeM(1) +')'"
              )
              polygon.shade(
                points="0 0, 100 160, 0 320" 
                fill="none"
                )
              g(:transform="'translate('+ sizeXR(320) +',0)'")
                polygon.shade(
                  points="320 0, 220 160, 320 320" 
                  fill="none"
                  )
              rect.reflex(
                :width="sizeXR(0)",
                height="320" 
                rx="16" 
                ry="16" 
                fill="none"
                )

            g.fix-marks(v-if ="store.fixersDisplay") 
              circle(
                cx="16" 
                cy="16" 
                r="3" 
                fill="#aaa"
                )
              circle(
                :cx="sizeM(sizeXR(0))-16",
                cy="16" 
                r="3" 
                fill="#aaa"
                )
              circle(
                :cx="sizeM(sizeXR(0))-16",
                :cy="sizeM(320)-16",
                r="3" 
                fill="#aaa"
                )
              circle(
                cx="16",
                :cy="sizeM(320)-16",
                r="3" 
                fill="#aaa"
                )

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "plateName"
              :isWhiteBack = "true"
              :immutableSize = "false"
              )          

        .sign-size.text-center 
          span розмір покажчика
          | {{sizeM(sizeXR(0))}}x{{sizeM(this.plateHeight)}} мм
    .row
      .col.svgContainer

  .container-fluid
    .row.row-cols-1.plate-options
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input.custom-control-input(type="checkbox" id="largerSize" v-model="store.sizeMultiplier")
            label.custom-control-label(for="largerSize") збільшений розмір покажчика
              br
              small.caption Якщо відстань від фасаду до краю хідника більше ніж&nbsp;15&nbsp;метрів      
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input#fixMarks.custom-control-input(type="checkbox" v-model="store.fixersDisplay")
            label.custom-control-label(for="fixMarks") показати кріплення
    .row.plate-download
      .col.text-center
        button#btn-download.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(false)") Завантажити                
        button#add-cart.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(true)") Додати до набору
    .row.plate-download
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center        
        p.caption.text-center Макет у форматі .pdf з літерами у кривих
          br
          br
          | Під час завантаження матеріалів ви погоджуєтесь з ліцензією 
          a(href="https://creativecommons.org/licenses/by-nd/4.0/deed.uk" target="_blank") CC BY-ND 4.0 Із 
          | Зазначенням Авторства — Без Похідних 4.0 Міжнародна       
    .svgInstance 
      svg#svgInstance(:width="sizeM(sizeXR(0))", :height="sizeM(400)",:viewBox="'0 0 '+ sizeM(sizeXR(0)) +' '+ sizeM(500)", preserveAspectRatio="xMidYMin meet", viewport xmlns="http://www.w3.org/2000/svg")        

  BulkDownloadList()
</template>

<script>
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

import StreetName from '@/components/StreetName.vue'
import SvgRequirements from '@/components/SvgRequirements.vue'
import SvgDefs from '@/components/SvgDefs.vue'
import SvgWave from '@/components/SvgWave.vue'
import BulkDownloadList from '@/components/BulkDownloadList.vue'

export default {
  name: 'Name',
  components: {    
    StreetName,
    SvgRequirements,
    SvgDefs,
    SvgWave,
    BulkDownloadList,
  },

  data(){
    return{
      store,

      mainFamily: '',
      baseName: '',
      mainTranslit: '',

      mainFamilyPlace: 'вулиця Дмитра',
      baseNamePlace: 'Яворницького',
      mainTranslitPlace: 'Dmytra Yavornytskoho St.',
      
      isMaterial: false,
      isPlaceholder: true,
      generateNotReady: true,
      isCollection: false,

      plateWidth:880,
      plateWidthMin:640,
      plateHeight:320,
      plateOffset:80,      
      plateLimit:120,

      rectR: 0,
      rectY: 0,
      rectW: 0,
      rectH: 0,

      plateName: 'Іменний покажчик',

      animationBuff: 0,
    }
  },

  watch:
  {    
    'store.inputStreetName': function()
    {
      this.minReq();      
    },

    'store.bulkData.length': function()
    {
      if(this.store.bulkData.length > 0)
      {
        this.store.isCartEmpty = false;
      }
      else
      {
        this.store.isCartEmpty = true;
      }
    },
  },

  methods:
  {
    minReq()
    {
      if(this.store.streetData.name == this.store.inputStreetName)
      {
        this.generateNotReady = false;
        this.isPlaceholder = false;
        this.isMaterial = true;

        this.baseName = '';
        
        if(this.store.streetData.prefix != "")
        {
          this.mainFamily = this.store.streetData.type + ' ' + this.store.streetData.prefix;
          this.baseName = this.store.streetData.name.substring(this.store.streetData.prefix.length + 1);
        }
        else
        {
          this.mainFamily = this.store.streetData.type;
          this.baseName = this.store.streetData.name;
        }

        this.mainTranslit = this.store.streetData.translation;
      }
      else
      {
        this.generateNotReady = true;        
        this.isMaterial = false;
        this.isPlaceholder = true;
        this.mainFamily = this.mainFamilyPlace;
        this.baseName = this.baseNamePlace;
        this.mainTranslit = this.mainTranslitPlace;
      }      
    },

    convertFunc(isBulk)
    {      
      let pdfMainName = (this.mainTranslit).toLowerCase().replace(/ /gm,'-').replace('.','');
      let pdfNameOptions = '_name-sign';
      if(this.store.sizeMultiplier)
      {
        pdfNameOptions += '_XL';
      }
      if(this.store.fixersDisplay)
      {
        pdfNameOptions += '_FIX';
      }
      pdfNameOptions += '.pdf';

      pdfFuncs.svgToPdfCovert(10, 10, this.sizeM(this.plateWidth), this.sizeM(500), this.sizeM(this.plateWidth)+20, this.sizeM(480)+30, 
      pdfMainName, pdfNameOptions, this.sizeM(30), this.sizeM(this.plateHeight + 35), this.sizeM(this.plateHeight), 'svgInstance', 'svgTemplate', isBulk, this.store.bulkData);

      if(isBulk)
      {
        this.getPosition();

        this.animationBuff = document.querySelector('#svgTemplate').outerHTML;

        let isCopy = false;

        if(typeof this.store.bulkData.length !== 'undefined')
        {
            for(let i = 0; i < this.store.bulkData.length; i++)
            {
                if(this.store.bulkData[i].pdfName == (pdfMainName + pdfNameOptions))
                {
                    isCopy = true;
                }  
            }
        }

        if(!isCopy)
        {
          setTimeout(() => {
            this.isCollection = true;
          }, 100); 
          setTimeout(() => {
            this.animationBuff = 0;
            this.isCollection = !this.isCollection;        
          }, 1000); 
        }
      }
    },

    getPosition() {      
      this.rectY = parseInt(this.$refs.refReady.getBoundingClientRect().y);
      this.rectW = parseInt(this.$refs.refReady.getBoundingClientRect().width);
      this.rectH = parseInt(this.$refs.refReady.getBoundingClientRect().height);
      this.rectR = parseInt(this.$refs.refReady.getBoundingClientRect().right);        
    },

    sizeM(size)
    {
      if(this.store.sizeMultiplier)
      {
        return size;
      }
      else
      {
        return size * 0.75;
      }
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },
    
    getWidth()
    {      
      var limitBox = parseInt(this.$refs.refLimit.getBoundingClientRect().width);
      var offsetBox = parseInt(this.$refs.refOffset.getBoundingClientRect().width);
      var contentBox = parseInt(this.$refs.refName.getBoundingClientRect().width);
      var translitBox = parseInt(this.$refs.refTranslit.getBoundingClientRect().width);

      if(limitBox < contentBox || limitBox < translitBox) 
      {       
        return this.plateWidth + this.plateOffset;
      }
      else if ((limitBox > contentBox || limitBox > translitBox) && (limitBox - contentBox > offsetBox) && (limitBox - translitBox > offsetBox) && this.sizeM(this.plateWidth) > this.sizeM(this.plateWidthMin))
      {
        return this.plateWidth - this.plateOffset;        
      }      
      else
      {
        return this.plateWidth;
      }
    },

    inputField() {
      this.$refs.search.$refs.inputField.focus();      
    },
  },
  
  beforeMount() {
    // if (Object.keys(this.streetData).length != 0) {
    //   this.isPlaceholder = false;
    //   this.isMaterial = true;      
    //   this.minReq();
    // }

    // console.log(this.$route.query.street)
    // console.log(this.$route.query.num)
  },

  mounted() {
    this.inputField();
    this.minReq();    
  },  

  updated(){
    this.$nextTick(()=>{     
      this.plateWidth = this.getWidth();
    });
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/_svg-templates.scss';

.preview {
  min-height: 330px;
  @include respond-below(md) {
    min-height: 240px;
    svg {
      width: 80%;
      padding: 0 20px;
      height: auto;
    }
  }
}
.container-fluid {
  max-width: 600px;
  &.full {
    max-width: none;
  }
}

.cartPreview {  
  display: inline-block; 
  width: 300px;
  height: 160px;

  position: fixed;
  top: 200px;
  right: calc(50% - 300px);
  
  visibility: hidden;
  opacity: 1;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 80px 0 rgba(0,0,0,.3);  
  border: none;

  &.collection {    
    visibility: visible;
    top: 50px !important;
    right: 50px !important;
    // background-color: rgba(255,255,255,1);
    
    opacity: 0;
    // z-index: 10000;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);

    transform: scale(.1);
    transform-origin: top right;

    transition: top .5s cubic-bezier(0, 0, 0.3, 1.8), right .5s ease-out, transform .5s ease-out, opacity .75s ease-in, box-shadow .5s ease-out;

  }
}

</style>